<template>
<div class="container padding-container">
    <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleDialogShow()">新增机构</el-button>
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :span="4">
                <span class="list-title">机构管理</span>
            </el-col>
            <el-col :span="20" class="search-area">
                <el-col :span="5" class="search-item">
                    <el-input class="circular-bead-input" placeholder="请输入机构名称" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)"
                        v-model='search.name'>
                    </el-input>
                </el-col>
                <el-col :span="5" class="search-item">
                    <el-input class="circular-bead-input" placeholder="请输入联系人名称" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)"
                        v-model='search.contactName'>
                    </el-input>
                </el-col>
                <el-col :span="5" class="search-item">
                    <el-input class="circular-bead-input" placeholder="请输入联系人手机" prefix-icon="el-icon-search"
                        title="按回车键搜索" @keyup.enter.native="pageChange(1)" @clear="pageChange(1)"
                        v-model='search.contactPhone'>
                    </el-input>
                </el-col>
            </el-col>
        </el-row>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>

                <el-table-column prop="name" label="机构名称" min-width="200" :show-overflow-tooltip="true">
                    <div slot-scope="scope" class="hover-text-colourful fz-bold" @click="routerChange(1, scope.row)">
                        {{scope.row.name}}
                    </div>
                </el-table-column>
                <el-table-column prop="contact_name" label="联系人姓名" min-width="120" align="center"/>
                <el-table-column prop="contact_phone" label="联系人手机" min-width="120" align="center"/>
                <template v-if="auth('ADMIN')">
                <el-table-column prop="school_count" label="学校数量" width="100" align="center"/>
                <el-table-column prop="class_count" label="班级数" width="100" align="center"/>
                <el-table-column prop="student_count" label="学生数" width="100" align="center"/>
                <el-table-column prop="type" label="机构类型" width="100" align="center">
                    <template slot-scope="scope">
                        {{scope.row.type === 2 ? '渠道客户' : '一般客户'}}
                    </template>
                </el-table-column>
                <el-table-column prop="service_type" label="服务类型" width="100" align="center">
                    <template slot-scope="scope">
                        {{scope.row.service_type | serviceTypeFilter}}
                    </template>
                </el-table-column>
                </template>

                <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" :min-width="auth('ADMIN') ? '200' : '120'" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="handleDialogShow(scope.row)">
                            <el-tooltip content="编辑" placement="top" :open-delay='700'>
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(1,scope.row)">
                            <el-tooltip content="详情" placement="top" :open-delay='700'>
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                        <template v-if="auth('ADMIN')">
                            <!-- <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                                <el-tooltip content="删除" placement="top" :open-delay='700'>
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button> -->
                            <el-button type="text" class="icon-btn" @click="handleUpdateShow('type',scope.row)">
                                <el-tooltip placement="top" content="设置机构类型">
                                    <i class="iconfont icon-shezhi" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleUpdateShow('serve',scope.row)">
                                <el-tooltip placement="top" content="设置服务类型">
                                    <i class="iconfont icon-shezhi" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </template>
                </el-table-column>
                <div slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
    <div class="dialog-container">
        <el-dialog :title="editForm.id ? '修改机构' : '新增机构'" :visible.sync="dialogVisible" :before-close="handleDialogClose" width="524px">
            <div class="form-item" v-if="!editForm.id && auth('ADMIN')">
                <div class="key">是否是渠道客户</div>
                <div class="value">
                    <el-radio-group v-model="editForm.type">
                        <el-radio :label="1">否</el-radio>
                        <el-radio :label="2">是</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="form-item">
                <div class="key">机构名称</div>
                <div class="value">
                    <el-input v-model="editForm.name" placeholder="请输入机构名称"></el-input>
                </div>
            </div>
            <div class="form-item">
                <div class="key">机构地址</div>
                <el-cascader class="value" placeholder="省/市/县" v-model="editForm.roughly_address"
                    :options="addressOptions"></el-cascader>
            </div>
            <div class="form-item">
                <div class="key">详细地址</div>
                <div class="value">
                    <el-input v-model="editForm.address" placeholder="请输入详细地址"></el-input>
                </div>
            </div>
            <div class="form-item">
                <div class="key">联系人姓名</div>
                <div class="value">
                    <el-input v-model="editForm.contact_name" placeholder="请输入联系人姓名"></el-input>
                </div>
            </div>
            <div class="form-item">
                <div class="key">联系人手机号</div>
                <div class="value">
                    <el-input v-model="editForm.contact_phone" placeholder="请输入联系人手机号"></el-input>
                </div>
            </div>
            <div class="form-item" v-if="!editForm.id && auth('ADMIN')">
                <div class="key">联系人密码</div>
                <div class="value">
                    <el-input v-model="editForm.password" placeholder="请输入联系人密码"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button type="primary" @click="handleDialogConfirm">确定</el-button>
                <el-button @click="handleDialogClose">取消</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="updateTitle" :visible.sync="updateVisible" :before-close="handleDialogClose" width="524px">
            <div class="form-item" v-if="updateType == 'type'">
                <div class="key">是否是渠道客户</div>
                <div class="value">
                    <el-radio-group v-model="editForm.type">
                        <el-radio :label="1">否</el-radio>
                        <el-radio :label="2">是</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="form-item" v-if="updateType == 'serve'">
                <div class="key">服务类型</div>
                <div class="value">
                    <el-radio-group v-model="editForm.service_type">
                        <el-radio :label="0">全部服务</el-radio>
                        <el-radio :label="2">仅小程序</el-radio>
                        <el-radio :label="3">仅后台</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button type="primary" @click="handleUpdateType">确定</el-button>
            </span>
        </el-dialog>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import axios from 'axios';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            search: {
                name: '',
                contactName: '',
                contactPhone: '',
                status: '',
            },

            dialogVisible: false,
            addressOptions: [],
            editForm: {
                id: '',
                name: '',
                type: 1,
                roughly_address: [],
                address: '',
                contact_name: '',
                contact_phone: '',
                password: '',

                service_type: '',
            },

            updateVisible: false,
            updateType: 'type',
            updateTitle: '',
        };
    },
    created() {
        this.getTableData();
        this.getRoughlyAddressList();
    },
    methods: {
        auth: Core.Utils.auth,
        getRoughlyAddressList() {
            axios.get('/ext/region-code.json').then((response) => {
                this.addressOptions = response.data;
            });
        },
        pageChange(page) {
            // 页码改变
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            // 列表信息行数改变
            this.pageSize = size;
            this.getTableData();
        },
        routerChange(key, q = '') {
            // 路由改变
            let detail = JSON.stringify({
                name: q.name,
                contact_name: q.contact_name,
                contact_phone: q.contact_phone,
                create_time: q.create_time
            });
            switch (key) {
                case 1: // 查看详情
                    this.$router.push({
                        path: '/org/org-detail',
                        query: {
                            id: q.id,
                            detail,
                        }
                    });
                    break;
            }
        },
        getTableData() {
            // 获取列表数据
            let apiName = this.auth('ADMIN') ? 'listByAdmin' : 'list'
            Core.Api.Org[apiName](
                this.currentPage,
                this.pageSize,
                this.search.name,
                this.search.contactName,
                this.search.contactPhone,
                this.search.status,
            ).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },

        handleDialogShow(item) {
            // 显示 编辑机构 弹框
            if (item) {
                this.editForm.id = item.id
                this.editForm.name = item.name
                this.editForm.type = item.type
                this.editForm.contact_name = item.contact_name
                this.editForm.contact_phone = item.contact_phone

                this.editForm.roughly_address = [ item.province, item.city, item.county ];
                this.editForm.address = item.detail_address;
            }
            this.dialogVisible = true;
        },
        handleDialogClose() {
            // 关闭 编辑机构 弹框
            this.dialogVisible = false;
            this.updateVisible = false
            Object.assign(this.$data.editForm, this.$options.data().editForm);
        },
        handleDialogConfirm() {
            // 确定 编辑机构
            let form = Core.Utils.deepCopy(this.editForm)
            let apiName = form.id ? 'save' : (this.auth('ADMIN') ? 'create' : 'save')
            if (!form.name) {
                return this.$message.warning('请输入机构名称');
            }
            if (!form.contact_name) {
                return this.$message.warning('请输入联系人姓名');
            }
            if (!form.contact_phone) {
                return this.$message.warning('请输入联系人手机号');
            } else if (!/^1[3456789]\d{9}$/.test(form.contact_phone)) {
                return this.$message.warning('联系人手机号码有误，请正确填写');
            }
            if (!form.id && this.auth('ADMIN') && !form.password) { // 新增
                return this.$message.warning('请输入联系人密码');
            }

            Core.Api.Org[apiName](
                form.id || 0,
                form.name,
                1,
                form.contact_name,
                form.contact_phone,
                form.roughly_address[0],
                form.roughly_address[1],
                form.roughly_address[2],
                form.address,
                form.password,
                form.type || 1,
            ).then(() => {
                this.$message.success('保存成功');
                this.getTableData();
                this.handleDialogClose();
            });
        },

        handleUpdateShow(type, item) {
            this.updateType = type
            this.updateTitle = type == 'type' ? '设置机构类型' : '设置机构服务类型'

            this.editForm.id = item.id
            this.editForm.type = item.type
            this.editForm.service_type = item.service_type
            this.updateVisible = true
        },
        handleUpdateType() {
            let type, apiName
            switch (this.updateType) {
                case 'type':
                    apiName = 'updateType';
                    type = this.editForm.type;
                    break;
                case 'serve':
                    apiName = 'updateServeType';
                    type = this.editForm.service_type || 0;
                    break;
            }
            Core.Api.Org[apiName](
                this.editForm.id,
                type
            ).then(() => {
                this.$message.success('保存成功');
                this.getTableData();
                this.handleDialogClose();
            })
        },

        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Org.delete(id).then(() => {
                    this.$message.success('删除成功');
                    this.getTableData();
                });
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-area {
        display: flex;
        justify-content: flex-end;
        .search-item + .search-item {
            margin-left: 16px;
        }
    }
    .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            color: #8090a6;
            font-size: 14px;
            width: 120px;
            text-align: right;
            box-sizing: border-box;
            padding-right: 20px;
        }
        .value {
            width: calc(100% - 120px);
            box-sizing: border-box;
            padding-right: 20px;
        }
    }
}
</style>